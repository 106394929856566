import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LooksOne from '@material-ui/icons/LooksOne';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                variant="fullWidth"
        >
          <Tab label="スマホ" {...a11yProps(0)} />
          <Tab label="PC" {...a11yProps(1)} />
          <Tab label="PS4" {...a11yProps(2)} />
          <Tab label="Nintendo Switch" {...a11yProps(3)} />
          <Tab label="その他" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Divider />
        <Link to="/item_list" style={{textDecoration:"none"}}><Icon color="secondary">looks_one</Icon>フェイト グランドオーダー(FGO)</Link>
        <Divider />
        <Icon color="secondary">looks_two</Icon>モンスターストライク(モンスト)
        <Divider />
        <Icon color="secondary">looks_3</Icon>グランブルーファンタジー(グラブル)
        <Divider />        
        <Icon color="secondary">looks_4</Icon>パズル アンド ドラゴンズ(パズドラ)
        <Divider />        
        <Icon color="secondary">looks_5</Icon>プリンセスコネクト(プリコネ)
        <Divider />        
        <Icon color="secondary">looks_6</Icon>荒野行動
        <Divider />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Divider />
        <Icon color="secondary">looks_one</Icon>リーグオブレジェンド(LoL)
        <Divider />
        <Icon color="secondary">looks_two</Icon>ドラゴンクエスト10(DQ10)
        <Divider />
        <Icon color="secondary">looks_3</Icon>ファイナルファンタジー14(FF14)
        <Divider />
        <Icon color="secondary">looks_4</Icon>リネージュ2(リネ2)
        <Divider />
        <Icon color="secondary">looks_5</Icon>ファンタシースターオンライン2(PSO2)
        <Divider />
        <Icon color="secondary">looks_6</Icon>アラド戦記
        <Divider />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Divider />
        <Icon color="secondary">looks_one</Icon>モンスターハンターワールド(モンハンW)
        <Divider />
        <Icon color="secondary">looks_two</Icon>ファイナルファンタジー14(FF14)
        <Divider />
        <Icon color="secondary">looks_3</Icon>ドラゴンクエスト10(DQ10)
        <Divider />
        <Icon color="secondary">looks_4</Icon>ファンタシースターオンライン2(PSO2)
        <Divider />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Divider />
        <Icon color="secondary">looks_one</Icon>フォートナイト
        <Divider />
        <Icon color="secondary">looks_two</Icon>大乱闘スマッシュブラザーズ(スマブラ)
        <Divider />
        <Icon color="secondary">looks_3</Icon>マリオカート(マリカー)
        <Divider />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Divider />
        <Icon color="secondary">looks_one</Icon>オンライン将棋
        <Divider />
        <Icon color="secondary">looks_two</Icon>オンライン麻雀
        <Divider />
        <Icon color="secondary">looks_3</Icon>オンラインチェス
        <Divider />
      </TabPanel>
    </div>
  );
}