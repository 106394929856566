import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from "./Header";
import Item from "./Item";
import Login from "./Login";
import EditUser from "./EditUser";
import Sell from "./Sell";
import ItemDetail from "./ItemDetail";
import ItemList from "./ItemList";
import TopPage from "./TopPage";
import Footer from "./Footer";
import ScrollTop from "./ScrollToTop";
import FloatingAddItemButton from "./FloatingAddItemButton";

function home(){
  return(
    <React.Fragment>
      <ScrollTop>
      <CssBaseline />
      <Header />
      <TopPage />
      <Footer />
      <FloatingAddItemButton />
      <ItemList />
      <ItemDetail />
      <Sell />
      <Login />
      <EditUser />
      </ScrollTop>
    </React.Fragment>
  );
}

function itemList(){
  return(
    <React.Fragment>
    <ScrollTop>
    <Header />
    <ItemList />
    <Footer />
    </ScrollTop>
    </React.Fragment>
  );
}

function itemDetail(){
  return(
    <React.Fragment>
    <ScrollTop>
    <Header />
    <ItemDetail />
    <Footer />
    </ScrollTop>
    </React.Fragment>
  );
}

function sell(){
  return(
    <React.Fragment>
    <ScrollTop>
    <Header />
    <Sell />
    <Footer />
    </ScrollTop>
    </React.Fragment>
  );
}

function login(){
  return(
    <React.Fragment>
    <ScrollTop>
    <Header />
    <Login />
    <Footer />
    </ScrollTop>
    </React.Fragment>
  );
}

export default function App(){
  return(
    <div>now bulding...</div>//hostingに蓋をするとき用
  );

  return(
    <BrowserRouter>
      <div>
        <Route exact path='/' component={home} />
        <Route path='/item_list' component={itemList} />
        <Route path='/item_detail' component={itemDetail} />
        <Route path='/sell' component={sell} />
        <Route path='/login' component={login} />
      </div>
    </BrowserRouter>
      
  );
}

