import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { red, pink, indigo } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton"
import SelectGenderRadio from "./SelectGenderRadio"
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Item from "./Item";

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          padding: theme.spacing(3),
        },
    },
    submitButton: {
        marginTop: theme.spacing(3),
    },
    bigAvatar: {
        margin: 10,
        width: 100,
        height: 100,
    },
    // fab: {
    //     margin: theme.spacing(1),
    // },
}));

export default function ItemList(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="inherit" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                「ゲーム名」のお仕事一覧
                </Typography>
            </Toolbar>
        </AppBar>
        <AppBar position="absolute" color="default" className={classes.appBar}>
            <Toolbar>
                <Grid container spacing={3} justify="center" alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="inherit">
                            出品数:50件
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="paymentMothod">ソート</InputLabel>
                            <Select
                            // value={props.orderInfo.paymentMothod}
                            // onChange={props.handleChange}
                            name="sortMenu"
                            inputProps={{
                                id: 'sortMenu',
                            }}
                            >
                            <MenuItem value="スマートフォンアプリ">新着順</MenuItem>
                            <MenuItem value="PC">出品者の評価順</MenuItem>
                            <MenuItem value="その他">性別順（男性、女性、未設定）</MenuItem>
                            <MenuItem value="その他">性別順（女性、男性、未設定）</MenuItem>
                            <MenuItem value="Playstation4">高い順</MenuItem>
                            <MenuItem value="Nintendo Switch">安い順</MenuItem>
                            </Select>
                            {/* <FormHelperText>選択してください</FormHelperText> */}
                        </FormControl>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <main className={classes.layout}>
            <Divider />
            <Item />
            <Divider />
            <Item />
            <Divider />
            <Item />
            <Divider />
        </main>
        </React.Fragment>
    );
}
