// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCuC96qVaMxVOgjaX8cGZoqvk45JsOBxLE",
    authDomain: "gameworks-84f41.firebaseapp.com",
    databaseURL: "https://gameworks-84f41.firebaseio.com",
    projectId: "gameworks-84f41",
    storageBucket: "gameworks-84f41.appspot.com/",
    messagingSenderId: "334052034871",
    appId: "1:334052034871:web:cd6aefa24810db70"
};

firebase.initializeApp(firebaseConfig)

export default firebase  

