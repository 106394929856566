import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputSell from './InputSell';
import PaymentForm from './PaymentForm';
import SellConfirmation from './SellConfirmation';
import Thanks from './Thanks';
// import * as MyConst from "./config/MyConst";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Checkout(props) {

    const classes = useStyles();
    const [orderInfo, setOrderInfo] = React.useState({
        firstName : "",
        lastName : "",
        address1 : "",
        address2 : "",
        paymentMothod : "",
        paymentName : "",
        email : "",
    });

    const handleChange = event => {
        setOrderInfo({...orderInfo, [event.target.name]: event.target.value});
    }    

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
    setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
    setActiveStep(activeStep - 1);
    };

    const steps = ['内容入力', '内容確認', '完了'];

    function getStepContent(step) {
        switch (step) {
            case 0:
            return <InputSell orderInfo={orderInfo} handleChange={handleChange}/>;
            case 1:
            return <SellConfirmation orderInfo={orderInfo} handleChange={handleChange} />;
            case 2:
            return <Thanks cartItems={props.cartItems} orderInfo={orderInfo} />;
            default:
            throw new Error('Unknown step');
        }
    }

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            出品する
          </Typography>
        </Toolbar>
      </AppBar> */}
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            仕事を出品する
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
                <Thanks orderInfo={orderInfo} cartItems={props.cartItems} />
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                    {(() => {
                        switch (activeStep) {
                            case 0:
                                return <React.Fragment>
                                        <Button variant="contained" color="primary" className={classes.button}>下書き保存</Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}>
                                            次へ
                                        </Button>
                                        </React.Fragment>;
                            case 1:
                                return <React.Fragment>
                                        <Button onClick={handleBack} className={classes.button}>戻る</Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleNext}
                                            className={classes.button}>
                                            出品する
                                        </Button>
                                        </React.Fragment>;
                            case 2:
                                return <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}>
                                            トップへ戻る
                                        </Button>
                                        </React.Fragment>;
                            default:
                                throw new Error('Unknown step');
                        }
                    })()}

                  {/* {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      戻る
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? '出品する' : '次へ'}
                  </Button> */}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  );
}
