import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton"
import SelectGenderRadio from "./SelectGenderRadio"
import SwipeableStepper from "./SwipeableStepper";
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import SellButtomArea from "./SellButtomArea";
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import GameRanking from "./GameRanking";
import Item from "./Item";
import Divider from '@material-ui/core/Divider';
import Review from "./Review";
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import { red, pink, indigo,grey, blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
      alignItems: 'center',
    },
    subAppBar:{
        position: 'relative',
        // backgroundColor: grey[500],
        marginTop: 50,
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          padding: theme.spacing(3),
        },
    },
    siteThemeText: {
        padding:15,
        marginTop: 30,
        marginBottom: 30,
        // backgroundColor: theme.palette.grey[200],
    },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/user/erondu)',
        // backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/shop-f8948.appspot.com/o/az.jpg?alt=media&token=b57ee605-5956-4b15-8e3d-2e11fc49e518)",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },    
}));

const featuredPosts = [
    {
        title: 'Featured post',
        date: 'Nov 12',
        description:
        'This is a wider card with supporting text below as a natural lead-in to additional content.',
    },
    {
        title: 'Post title',
        date: 'Nov 11',
        description:
        'This is a wider card with supporting text below as a natural lead-in to additional content.',
    },
];
  
export default function ItemDetail(props) {
    const classes = useStyles();

    return (
    <React.Fragment>
        <CssBaseline />
        <main>
          {/* Main featured post */}
          <Paper className={classes.mainFeaturedPost}>
            {/* Increase the priority of the hero background image */}
            {
              <img
                style={{ display: 'none' }}
                src="https://source.unsplash.com/user/erondu"
                // src="https://firebasestorage.googleapis.com/v0/b/shop-f8948.appspot.com/o/az.jpg?alt=media&token=b57ee605-5956-4b15-8e3d-2e11fc49e518"
                alt="background"
              />
            }
            <div className={classes.overlay} />
            <Grid container>
              <Grid item md={6}>
                <div className={classes.mainFeaturedPostContent}>
                  {/* <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                    一部のトッププレイヤーだけが、ゲームを仕事にできると考えていませんか？
                  </Typography> */}
                  <Typography variant="h6" color="inherit" paragraph>
                    誰かと一緒に、話しながら楽しくゲームをする。<br />
                    そういうことが仕事になる・仕事としてお願いできる場所。<br />
                    それが「Game Works」です。
                  </Typography>
                  <Link to="/" style={{ color: 'white' }}>
                    はじめての方はこちら…
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Paper>
          {/* <Grid container spacing={4} className={classes.cardGrid}>
            {featuredPosts.map(post => (
              <Grid item key={post.title} xs={12} md={6}>
                <CardActionArea component="a" href="#">
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent>
                        <Typography component="h2" variant="h5">
                          {post.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {post.date}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          {post.description}
                        </Typography>
                        <Typography variant="subtitle1" color="primary">
                          Continue reading...
                        </Typography>
                      </CardContent>
                    </div>
                    <Hidden xsDown>
                      <CardMedia
                        className={classes.cardMedia}
                        image="https://source.unsplash.com/random"
                        title="Image title"
                      />
                    </Hidden>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>           */}
        </main>        
        {/* <AppBar position="relative" color="default">
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                    お仕事 人気ゲームランキング
                </Typography>
            </Toolbar>
        </AppBar>
        <Paper>
            <GameRanking />
        </Paper> */}
        {/* <AppBar position="absolute" color="default" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" color="inherit" className={classes.siteThemeText}　noWrap>
                    プロゲーマーという言葉がようやく定着しはじめた令和。<br />
                    一部のトッププレイヤーだけが、ゲームを仕事にできると考えていませんか？<br />
                    誰かと一緒に、話しながら楽しくゲームをする。<br />
                    ゲームのコツを教えたり・教えられたりする。<br />
                    そういうことが仕事になる・仕事としてお願いできる場所。それが「Game Works」です。
                </Typography>
            </Toolbar>
        </AppBar> */}
        <AppBar position="relative" color="default" className={classes.subAppBar}>
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                    最近出品されたお仕事
                </Typography>
            </Toolbar>
        </AppBar>
        {/* <main className={classes.layout}> */}
        {/* <Paper> */}
        <Grid container spacing={5}　justify="center" alignItems="center">
            <Grid item>
                <Item cardWidth={345} />
            </Grid>
            <Grid item>
                <Item cardWidth={345} />
            </Grid>
            <Grid item>
                <Item cardWidth={345} />
            </Grid>
        </Grid>
        {/* </Paper> */}
        {/* </main> */}
        <AppBar position="relative" color="default" className={classes.subAppBar}>
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                    お仕事 人気ゲームランキング
                </Typography>
            </Toolbar>
        </AppBar>
        <Paper>
            <GameRanking />
        </Paper>
        <AppBar position="relative" color="default"  className={classes.subAppBar}>
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                    新着レビュー
                </Typography>
            </Toolbar>
        </AppBar>
        {/* <main className={classes.layout}> */}
            <Paper className={classes.paper}>
                <Review />
                <Review />
            </Paper>
        {/* </main> */}
    </React.Fragment>
    );
}
