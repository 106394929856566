import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
      backgroundColor: theme.palette.grey[200],
      marginTop:5,
      marginBottom:20,
  },
}));

export default function AddressForm(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.sectionTitle}>
        <Grid item xs={12}>
          <Typography variant="h6">
            お仕事内容
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Fab color="primary" aria-label="add">
          <AddIcon />
        </Fab>
        <Typography variant="caption">
          ※画像は3枚まで追加できます
        </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="ゲーム名"
            value={props.orderInfo.address1}
            fullWidth
            autoComplete="billing address-line1"
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
            <FormControl required fullWidth>
                <InputLabel htmlFor="paymentMothod">スマホアプリ、PC、ゲーム機などのカテゴリー</InputLabel>
                <Select
                value={props.orderInfo.paymentMothod}
                onChange={props.handleChange}
                name="paymentMothod"
                inputProps={{
                    id: 'paymentMothod',
                }}
                >
                <MenuItem value="スマートフォンアプリ">スマートフォンアプリ</MenuItem>
                <MenuItem value="PC">PC</MenuItem>
                <MenuItem value="Playstation4">Playstation4</MenuItem>
                <MenuItem value="Nintendo Switch">Nintendo Switch</MenuItem>
                <MenuItem value="その他">その他</MenuItem>
                </Select>
                <FormHelperText>選択してください</FormHelperText>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
            <FormControl required fullWidth>
                <InputLabel htmlFor="paymentMothod">お仕事中の通話方法</InputLabel>
                <Select
                value={props.orderInfo.paymentMothod}
                onChange={props.handleChange}
                name="paymentMothod"
                inputProps={{
                    id: 'paymentMothod',
                }}
                >
                <MenuItem value="Discord">Discord</MenuItem>
                <MenuItem value="その他">その他</MenuItem>
                </Select>
                <FormHelperText>選択してください</FormHelperText>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
        <TextField
              // variant="outlined"
              margin="normal"
              required
              fullWidth
              name="memo"
              label="説明"
              id="memo"
              multiline={true}
              rows={4}
              value=""
              // onChange={}
            />          
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="1時間の価格(半角数字)"
            value={props.orderInfo.address2}
            fullWidth
            autoComplete="billing address-line2"
            onChange={props.handleChange}
          />円
        </Grid>
        <Grid item xs={6}>
            <FormControl required fullWidth>
                <InputLabel htmlFor="paymentMothod">お仕事の最小時間</InputLabel>
                <Select
                value={props.orderInfo.paymentMothod}
                onChange={props.handleChange}
                name="minOrder"
                inputProps={{
                    id: 'minOrder',
                }}
                >
                <MenuItem value="1">1時間</MenuItem>
                <MenuItem value="2">2時間</MenuItem>
                <MenuItem value="1">3時間</MenuItem>
                <MenuItem value="2">4時間</MenuItem>
                <MenuItem value="1">5時間</MenuItem>
                <MenuItem value="2">6時間</MenuItem>
                <MenuItem value="1">7時間</MenuItem>
                <MenuItem value="2">8時間</MenuItem>
                <MenuItem value="1">9時間</MenuItem>
                <MenuItem value="2">10時間</MenuItem>
                <MenuItem value="1">11時間</MenuItem>
                <MenuItem value="2">12時間</MenuItem>
                </Select>
                <FormHelperText>選択してください</FormHelperText>
            </FormControl>
        </Grid>
        <Grid item xs={6}>
            <FormControl required fullWidth>
                <InputLabel htmlFor="paymentMothod">お仕事の最大時間</InputLabel>
                <Select
                value={props.orderInfo.paymentMothod}
                onChange={props.handleChange}
                name="maxOrder"
                inputProps={{
                    id: 'maxOrder',
                }}
                >
                <MenuItem value="1">1時間</MenuItem>
                <MenuItem value="2">2時間</MenuItem>
                <MenuItem value="1">3時間</MenuItem>
                <MenuItem value="2">4時間</MenuItem>
                <MenuItem value="1">5時間</MenuItem>
                <MenuItem value="2">6時間</MenuItem>
                <MenuItem value="1">7時間</MenuItem>
                <MenuItem value="2">8時間</MenuItem>
                <MenuItem value="1">9時間</MenuItem>
                <MenuItem value="2">10時間</MenuItem>
                <MenuItem value="1">11時間</MenuItem>
                <MenuItem value="2">12時間</MenuItem>
                </Select>
                <FormHelperText>選択してください</FormHelperText>
            </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}