import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationIcon from '@material-ui/icons/Navigation';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(theme => ({
  fab: {
        margin: 0,
        top: 'auto',
        right: -5,
        bottom: -5,
        left: 'auto',
        position: 'fixed',
        width: 100,
        height: 100,
        zIndex: 99999,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function FloatingActionButtons() {
  const classes = useStyles();

  return (
    <div>
    <Link to="/sell">
      <Fab color="secondary" size="large" aria-label="add" className={classes.fab}>
        {/* <AddIcon />出品 */}
        出品
      </Fab>
    </Link>
    </div>
  );
}