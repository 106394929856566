import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { red, pink, indigo } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          padding: theme.spacing(3),
        },
    },
    submitButton: {
        marginTop: theme.spacing(3),
    },
}));

export default function SellButtomArea(props){
    const classes = useStyles();

    return(
        <Paper className={classes.paper}>
        <Typography variant="h5">1時間:1,500円</Typography>
        <Grid item xs={6}>
            <FormControl required fullWidth>
                <InputLabel htmlFor="paymentMothod">購入時間数</InputLabel>
                <Select
                // value={props.orderInfo.paymentMothod}
                // onChange={props.handleChange}
                name="orderTimes"
                inputProps={{
                    id: 'orderTimes',
                }}
                >
                <MenuItem value="1">1時間</MenuItem>
                <MenuItem value="2">2時間</MenuItem>
                <MenuItem value="1">3時間</MenuItem>
                <MenuItem value="2">4時間</MenuItem>
                <MenuItem value="1">5時間</MenuItem>
                <MenuItem value="2">6時間</MenuItem>
                <MenuItem value="1">7時間</MenuItem>
                <MenuItem value="2">8時間</MenuItem>
                <MenuItem value="1">9時間</MenuItem>
                <MenuItem value="2">10時間</MenuItem>
                <MenuItem value="1">11時間</MenuItem>
                <MenuItem value="2">12時間</MenuItem>
                </Select>
                {/* <FormHelperText>選択してください</FormHelperText> */}
            </FormControl>
        </Grid>

        <Button fullWidth variant="contained" color="secondary" className={classes.submitButton}>
            無料登録して購入する
        </Button>             
        <Button fullWidth variant="outlined" color="default" className={classes.submitButton}>
            {/* <ChatOutlined /> */}
            <Icon  color="disabled">textsms</Icon>
            コメントする
        </Button>
        <Typography align="right">
            >購入ガイドはこちら
        </Typography>                  
        </Paper>
    );    
}

