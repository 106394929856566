import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { red, pink, indigo } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton"

const useStyles = makeStyles(theme => ({
    // paper: {
    //     marginTop: theme.spacing(1),
    //     marginBottom: theme.spacing(1),
    //     padding: theme.spacing(2),
    //     [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //       marginTop: theme.spacing(2),
    //       marginBottom: theme.spacing(2),
    //       padding: theme.spacing(3),
    //     },
    // },
    main: {
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
    },

    commentAvatar: {
        margin: 5,
        width: 50,
        height: 50,
    },
    commentText: {
        padding:15,
        backgroundColor: theme.palette.grey[200],
    },
}));


export default function Comment(props){
    const classes = useStyles();

    return(
        <Grid container justify="center" className={classes.main}>
            <Grid item xs={3}>
                <IconButton>
                    <Avatar 
                    src="https://firebasestorage.googleapis.com/v0/b/shop-f8948.appspot.com/o/az.jpg?alt=media&token=b57ee605-5956-4b15-8e3d-2e11fc49e518" 
                    className={classes.commentAvatar}
                    />
                </IconButton>
            </Grid>
            <Grid item xs={7}>
                <Typography>ザリガニ大好き小池さん</Typography>
                <Paper className={classes.commentText}>
                    <Typography>
                        あああああああああああああああああああああああああああああ
                        あああああああああああああああああああああああああああああああああ
                        あああああああああああああああああああああああああああああああああ
                        あああああああああああああああああああああああああああああ
                        あああああああああああああああああああああああああああ
                    </Typography>
                    <Typography variant="body2" align="right">
                        3日前
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}