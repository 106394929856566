import React, { useState, useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button,Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import twitterLogo from "./svg/twitterLogo.svg";
import yahooLogo from "./svg/yahooLogo.svg";
import googleLogo from "./svg/googleLogo.svg";
import facebookLogo from "./svg/facebookLogo.svg";
import gameCenterLogo from "./svg/gameCenterLogo.svg";
import microsoftLogo from "./svg/microsoftLogo.svg";
import firebase from "./firebase/Firebase";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
          backgroundColor: theme.palette.common.white,
        },
        ul: {
          margin: 30,
          padding: 0,
        },
        li: {
          listStyle: 'none',
        },
    },

    cardHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    },
    
    webButton: {
        textTransform: 'none',
        marginBottom: 20,
    }
}));

const platforms = [
    {
        id: 0,
        name: "Twitterアカウント",
        src: twitterLogo,
        alt: "twitterLogo",
    },
    {
        id: 1,
        name: "Googleアカウント",
        src: googleLogo,
        alt: "googleLogo",
    }, 
    {
        id: 2,
        name: "Facebookアカウント",
        src: facebookLogo,
        alt: "facebookLogo",
    },
    {
        id: 3,
        name: "Microsoftアカウント",
        src: microsoftLogo,
        alt: "microsoftLogo",
    },
    // {
    //     id: 4,
    //     name: "GameCenter",
    //     src: gameCenterLogo,
    //     alt: "gameCenterLogo",
    // },        
    {
        id: 5,
        name: "Yahoo! ID",
        src: yahooLogo,
        alt: "yahooLogo",
    },        

]

function login(platform) {
    switch(platform){
        case 0://twitter
            var provider = new firebase.auth.TwitterAuthProvider();
            break;

        case 1://Google
            var provider = new firebase.auth.GoogleAuthProvider();
            break;
        
        case 2://Facebook
            var provider = new firebase.auth.FacebookAuthProvider();
            break;

        case 3://Microsoft
            var provider = new firebase.auth.MicrosoftAuthProvider();
            break;

        case 4://GameCenter
            var provider = new firebase.auth.GameCenterAuthProvider();
            break;

        case 5://Yahoo
            var provider = new firebase.auth.YahooAuthProvider();
            break;

        case 6:
            firebase.auth().signInAnonymously()//匿名でのテスト用。
            break;
    }

    firebase.auth().signInWithRedirect(provider);
}

function logout() {
    firebase.auth().signOut();
}


export default function Login(){
    const classes = useStyles();
    const [user, setUser] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            setUser(user);
        })
    });

    function createUserWithEmail(email, password){
        firebase.auth().createUserWithEmailAndPassword(email, password).then(function(){
            var user = firebase.auth().currentUser;
            setUser(user);
            
        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(email, password);
            console.log(errorCode);
            console.log(errorMessage);
          });
    }        

    return(
        <Container maxWidth="md" component="main">
        {/* <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={12}> */}
            <Card>
                <CardHeader
                    title="ここから先のご利用は、会員登録が必要です"
                    subheader="会員登録は無料。15秒で登録できます！"
                    titleTypographyProps={{ align: 'center' ,variant: "subtitle1"}}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                />
                <CardActions>
                    <ul >
                        <Button fullWidth variant="contained" color="primary" component="li" className={classes.webButton} onClick={()=>createUserWithEmail("info@happygamer.co.jp", "onogaua790jw")}>
                            メールアドレスで登録
                        </Button>
                        {platforms.map(platform => (
                            <Button fullWidth variant="outlined" color="primary" component="li" className={classes.webButton} onClick={()=>login(platform.id)}>
                                <Grid style={{height: 30,}} container justify="center" alignItems="center">
                                    <Grid item xs={2}><img key={platform.name} src={platform.src} alt={platform.alt} width={30} height={30} /></Grid>
                                    <Grid item xs={8}>{platform.name}で登録</Grid>
                                </Grid>
                            </Button>
                        ))}
                    <Typography　align="right">
                        ログインはこちら
                    </Typography>
                    <div>
                        {user && "User :" + user.displayName}
            
                        {user ? (
                            <Button color="inherit" onClick={logout}>ログアウト</Button>
                        ) : (
                            <Button color="inherit" onClick={()=>login(6)}>匿名ログイン</Button>
                        )}
                    </div>
                    </ul>
                </CardActions>
            </Card>
            {/* </Grid>
        </Grid> */}
        </Container>
    )
}