import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { red, pink, indigo } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton"
import SelectGenderRadio from "./SelectGenderRadio"

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          padding: theme.spacing(3),
        },
    },
    submitButton: {
        marginTop: theme.spacing(3),
    },
    bigAvatar: {
        margin: 10,
        width: 100,
        height: 100,
    },
    // fab: {
    //     margin: theme.spacing(1),
    // },
}));

export default function EditUser(props) {
    const classes = useStyles();

    return (
    <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                ユーザー情報の編集
                </Typography>
            </Toolbar>
        </AppBar>
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                {/* <label htmlFor="contained-button-file"> */}
                    <IconButton>
                        <Avatar 
                        src="https://firebasestorage.googleapis.com/v0/b/shop-f8948.appspot.com/o/az.jpg?alt=media&token=b57ee605-5956-4b15-8e3d-2e11fc49e518" 
                        className={classes.bigAvatar}
                        />
                        <Icon>edit_icon</Icon>
                        {/* <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                        /> */}
                    </IconButton>
                {/* </label> */}
                <Typography variant="subtitle2">ユーザーID:vrneogeadyfa</Typography>                
                <Paper  className={classes.paper}>
                    <Typography variant="h5"align="center">今年の売上:36,000円</Typography>
                    <Grid container justify="center" alignItems="center">
                        <Icon color="secondary">mood</Icon>1000
                        <Icon color="disabled">mood_bad</Icon>2000
                    </Grid>
                </Paper>
                <SelectGenderRadio />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="ニックネーム"
                        value={props}
                        fullWidth
                        autoComplete="name"
                        onChange={props}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        id="bio"
                        name="bio"
                        label="紹介文"
                        value={props}
                        fullWidth
                        onChange={props}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        id="email"
                        name="email"
                        label="通知を受け取るメールアドレス"
                        value={props}
                        fullWidth
                        onChange={props}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        id="line"
                        name="line"
                        label="通知を受け取るLine ID"
                        value={props}
                        fullWidth
                        onChange={props}
                    />
                    </Grid>
                </Grid>
                <Button fullWidth variant="contained" color="primary" className={classes.submitButton}>
                    設定を保存する
                </Button>
        </Paper>
        </main>
    </React.Fragment>
    );
}