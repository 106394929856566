import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { red, pink, indigo } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton"
import SelectGenderRadio from "./SelectGenderRadio"
import SwipeableStepper from "./SwipeableStepper";
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import Comment from "./Comment";
import SellButtomArea from "./SellButtomArea";
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
          padding: theme.spacing(3),
        },
    },
    submitButton: {
        marginTop: theme.spacing(3),
    },
    sellerAvatar: {
        margin: 10,
        width: 100,
        height: 100,
    },
    commentAvatar: {
        margin: 5,
        width: 50,
        height: 50,
    },
    commentText: {
        padding:15,
        backgroundColor: theme.palette.grey[200],
    },
    // fab: {
    //     margin: theme.spacing(1),
    // },
    male:{
        backgroundColor: indigo[500],
        color: "#ffffff",
      },
    female:{
        backgroundColor: pink[500],
        color: "#ffffff",
    },
}));

export default function ItemDetail(props) {
    const classes = useStyles();

    return (
    <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" color="inherit">
                ダベりプレイします
                </Typography>
            </Toolbar>
        </AppBar>
        <main className={classes.layout}>
            <SwipeableStepper />
            <SellButtomArea />
            <Paper className={classes.paper}>
                <Typography variant="h5">お仕事詳細<br /><br /></Typography>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>ゲーム名:</TableCell><TableCell>モンスターストライク,モンスト</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>カテゴリ:</TableCell><TableCell>スマートフォンアプリ</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>ゲームプレイ中の通話方法:</TableCell><TableCell>Discode</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Typography>
                    <br /><br />YouTuberです。モンストランク2000。ダベりプレイのお供にどうぞ。 どんなステージでも対応可能です。
                </Typography>                  
            </Paper>          
            <Paper className={classes.paper}>
                <Typography variant="h5">出品者情報</Typography>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={6}>
                        <IconButton>
                            <Avatar 
                            src="https://firebasestorage.googleapis.com/v0/b/shop-f8948.appspot.com/o/az.jpg?alt=media&token=b57ee605-5956-4b15-8e3d-2e11fc49e518" 
                            className={classes.sellerAvatar}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>ザリガニ大好き小池さん</Typography>
                        <Typography>
                            <span className={classes.female}>女性</span>
                        </Typography>
                        <Typography>
                            <Icon color="secondary">mood</Icon>1000
                            <Icon color="disabled">mood_bad</Icon>2000
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center"　alignItems="center">
                    <Grid item xs={9}>
                        <Typography>
                            YouTube,Mirativeで配信しています。主にモンスト、時々フォートナイト。
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.paper}>
                <Typography variant="h5">コメント</Typography>
                <Comment />
                <Comment />
            </Paper>
            <Paper className={classes.paper}>
                <Typography variant="h5">お支払い方法・お取引の流れ</Typography>
                <Typography>
                    <br />1.各決済方法(クレジットカード、コンビニ決済、銀行振込)にてお支払い<br />
                    <br />2.お仕事完了まで運営が代金を一旦お預かりします<br />
                    <br />3.出品者とコメントで連絡をとり、お仕事日を決めてください。<br />
                    <br />4.お仕事完了後、「お仕事受領」ボタンを押してください。この時点で運営から出品者へ代金が送られます。<br />
                </Typography>
            </Paper>
            <SellButtomArea />
        </main>
    </React.Fragment>
    );
}