import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { red, pink, indigo } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import * as MyConsts from "./config/MyConsts";


const useStyles = makeStyles(theme => ({
    footer: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(3),
      padding: theme.spacing(6, 0),
    },
    appBar: {
        position: 'relative',
        alignItems: 'center',
      },  
}));

export default function Footer(){
    const classes = useStyles();

    return(
        <React.Fragment>
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom>
                ご利用ガイド
                </Typography>
                <Typography variant="subtitle2" align="center" color="textSecondary" component="p">
                はじめての方はこちら　お取引の流れ<br />お仕事を出品する　お仕事を購入する<br />手数料・お支払い方法　よくあるご質問・ヘルプ
                </Typography>
            </Container>
        </footer>
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom>
                {MyConsts.siteName}
                </Typography>
                <Typography variant="subtitle2" align="center" color="textSecondary" component="p">
                特定商取引法表記　個人情報保護方針　利用規約<br />　お問い合わせ　サイトマップ
                </Typography>
            </Container>
        </footer>
        <AppBar className={classes.appBar}>
            <Toolbar>
                <Typography variant="subtitle1" color="inherit">
                    <Icon>copyright</Icon>2019 Matubusi,Inc All Rights Reserved.
                </Typography>
            </Toolbar>
        </AppBar>        
        </React.Fragment>
    );
}